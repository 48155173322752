<template>
  <div>
    <div class="search">
      <div class="searche_item">
        <el-input v-model="search_val" placeholder="请输入内容"></el-input>
      </div>
      <div class="searche_item">
        <el-button type="primary" @click="search_data">查询</el-button>
      </div>
      <div class="searche_item">
        <el-button type="text" @click="add_dialog()"> 添加 </el-button>
      </div>
    </div>
    <el-table border :data="tableList" style="width: 100%" :height="cheight">
      <el-table-column type="selection" width="55"> </el-table-column>

      <el-table-column label="名称">
        <template slot-scope="{ row }">
          <span>{{ row.kName }}</span>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="{ row, $index }">
          <el-button type="text" size="small" @click="edit_click(row)">
            修改
          </el-button>
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="deleteRow(row, $index)"
          >
            <el-button type="text" size="small" slot="reference">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="m10">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="recordCount"
        :page-size="query.limit"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <ArticleTypesKinds
      :row_type="sel_row"
      :show="show_type"
      @close="close_type"
      @update="getList"
    />
  </div>
</template>

<script>
import ArticleTypesKinds from "./ArticleTypesKindsAdd.vue";
export default {
  components: { ArticleTypesKinds },
  data() {
    return {
      sel_row: {},
      show_type: false,
      search_val: "",
      recordCount: 0,
      query: {
        page: 1,
        limit: 25,
      },
      tableList: [],
      cheight: window.innerHeight - 280,
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    search_data() {
      this.query.keyName = this.search_val;
      this.getList();
    },
    close_type() {
      this.show_type = false;
    },
    getList() {
      this.$api.article_types_kinds_list(this.query).then((res) => {
        this.tableList = res.data.rows;
        this.recordCount = res.data.total;
        // console.log(res.data);
      });
    },
    pageChange(currPage) {
      this.query.page = currPage;
      this.getList();
    },
    add_dialog() {
      this.sel_row = {
        id: "",
        kName: "",
      };
      this.show_type = true;
    },
    deleteRow(row) {
      console.log("deleteRow");
      this.$api.article_types_kinds_delete({ id: row.id }).then((res) => {
        this.getList();
        this.$notify({
          title: "温馨提示",
          message: res.data.msg,
          type: "success",
          duration: 2000,
        });
      });
    },
    edit_click(row) {
      this.sel_row = row;
      this.show_type = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .searche_item {
    padding-right: 10px;
  }
}
</style>
